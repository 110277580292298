import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const fetchOrganization = fetch(
    'FETCH_ORGANIZATION',
    'gt/customer/legal_entity_info/',
    {},
    undefined,
    showError
);

export const updateOrganization = fetch(
    'UPDATE_ORGANIZATION',
    'gt/customer/update_legal_entity_info/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
