import { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { Input } from '@components/Input';

import styles from './styles.module.scss';

const formatDate = (date) => (date ? moment(date).format('DD.MM.YYYY') : '');

export const DateViewer = ({ date, setPopupOpen, routeId }) => {
    const [formattedData, setFormattedData] = useState(formatDate(date));
    const isExistRoute = routeId !== -1;

    const buttonStyles = clsx(styles.datePicker, {
        [styles.disabled]: isExistRoute,
    });

    const onPopupOpen = () => {
        if (!isExistRoute) {
            setPopupOpen(true);
        }
    };

    useEffect(() => {
        setFormattedData(formatDate(date));
    }, [date]);

    return <Input value={formattedData} onClick={onPopupOpen} className={buttonStyles} />;
};
