import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const fetchImports = fetch(
    'FETCH_IMPORTS',
    'gt/customer/imports/',
    {},
    undefined,
    showError
);
