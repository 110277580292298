import { forwardRef, useEffect, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { Checkbox, Col, Collapse, Form, Row, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import styled from 'styled-components';

import { CustomAutocomplete } from '@components/CustomAutocomplete';
import { FormItem as UIFormItem } from '@components/FormItem';
import { Input } from '@components/Input';
import { NumberInput } from '@components/NumberInput';
import { PopupInfo } from '@components/PopupInfo';
import { fetchServices } from '@store/actions/requests';
import { platformSelector } from '@store/reducers/platform';
import { authorization, BACKEND_URL, DADATA_API_KEY } from '@utils';

import { accountInfo } from '../../store/actions';

import { AutoFillItem } from './AutoFillItem';
import { DateViewer } from './DateViewer';
import { FormControls } from './FormControls';
import { FormItemDependentOnRoute } from './FormItemDependentOnRoute';
import { useCreateAddresOrRequest } from './hook';
import { Label } from './Label';
import { NewRequestMobilePopup } from './NewRequestMobilePopup';
import { formatError, getBase64, nameItemsList, timeRequiredRule } from './utils';

import 'react-dadata/dist/react-dadata.css';
import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        flex: 0 0 0 !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

const FormItem = forwardRef((props, ref) => {
    const { children, label, noWrap } = props;
    return (
        <UIFormItem
            {...props}
            ref={ref}
            label={label ? <Label noWrap={noWrap}>{label}</Label> : label}
            marginBottom={0}
            notInfoMessage
            colon={false}
            isLabelVertical
        >
            {children}
        </UIFormItem>
    );
});

const formatDate = (date) => (date ? moment(date).format('DD.MM.YYYY') : '');

const transformToPossibleDisabledItem =
    (WrappedComponent, name, disableList, disablePropName = 'disable') =>
    (props) => {
        const isDisabled = disableList ? disableList.includes(name) : false;

        return <WrappedComponent {...props} {...{ [disablePropName]: isDisabled }} />;
    };

const getInitialState = (route) => ({
    // time_interval: [null, null],
    // time: [null, null],
    location: '',

    services: [
        {
            service: null,
            workers: null,
        },
    ],

    has_elevator: true,
    has_address: true,

    date: route?.date && moment(route.date, 'YYYY.MM.DD').toDate(),
});

const requiredRule = [{ required: true, message: '' }];

const serviceRequiredRule = [
    (form) => {
        const services = form.getFieldsValue()?.services;
        return {
            message: '1',
            type: 'boolean',
            validator() {
                if (
                    !!Array.isArray(services) &&
                    !!services?.length &&
                    services?.every((i) => !!i?.workers && !!i?.service)
                ) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
];

export const NewRequestPopup = ({ close, route, onSuccess, disableList, data }) => {
    const platform = useSelector(platformSelector);
    const onCreateAddresOrRequest = useCreateAddresOrRequest();
    const [form] = useForm();
    const dispatch = useDispatch();
    const { list: locationsList } = useSelector((state) => state.locations);
    const { list: servicesList } = useSelector((state) => state.services);
    // const { allow_requests_creation } = useSelector((state) => ({
    //     allow_requests_creation: state.user.info.allow_requests_creation,
    // }));
    const [routeOptions, setRouteOptions] = useState([]);
    const [locationId, setLocationId] = useState();
    const [routeId, setRouteId] = useState(route ? route.routeId : -1);
    const [datePopupOpen, setDatePopupOpen] = useState(false);
    const [timePopupOpen, setTimePopupOpen] = useState(false);
    const [oneTimeInput, setOneTimeInput] = useState(false);
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [uploadedImages, setUploadedImages] = useState([]);
    const [timeInputActive, setTimeInputActive] = useState(null);

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data]);

    const date = form.getFieldValue('date');
    const routeSearch = form.getFieldValue('route');
    useEffect(() => {
        updateRouteOptions();
    }, [routeSearch, date]);

    useEffect(() => {
        updateServicesOptions();
    }, [locationId]);

    const updateServicesOptions = async () => {
        if (locationId === -1) return;
        dispatch(
            fetchServices({
                locationId,
            })
        );
    };

    // TODO: переделать на общий запрос из экшиона
    const updateRouteOptions = async () => {
        if (!date) return;

        const { data } = await axios({
            method: 'get',
            url: `${BACKEND_URL}gt/customer/request_autocomplete/`,
            params: {
                q: routeSearch,
                forward: JSON.stringify({
                    date: formatDate(date),
                }),
            },
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        });

        setRouteOptions(data.results.map((r) => ({ text: r.text, id: +r.id })));
    };

    const submitForm = async ({ date, time, services, ...value }) => {
        const response = await dispatch(accountInfo());
        if (!response.payload.data.allow_requests_creation) {
            setInfoPopup({
                open: true,
                content: (
                    <>
                        Пополните баланс или <a href='/info'>добавьте карту</a> для подачи заявок
                    </>
                ),
                title: 'Недостаточно средств',
            });
            return;
        }
        try {
            await onCreateAddresOrRequest({
                date: formatDate(date),
                routeId,
                locationId,
                value: {
                    ...value,
                    time_interval: value?.time_interval?.split('-') || [null, null],
                },
                time: value?.time_interval?.split('-') || [time, '__:__'],
                services: services
                    ?.filter((i) => !!i?.service && !!i?.workers)
                    ?.map((i) => ({
                        workers_required: i?.workers ? Number(i?.workers) : undefined,
                        expected_hours: i?.expected_hours ? Number(i?.expected_hours) : undefined,
                        service_id: Number(servicesList?.find((j) => j?.text === i?.service)?.id),
                    })),
            });

            setInfoPopup({
                open: true,
                content: 'Заявка успешно создана',
                title: 'Результат',
            });

            onSuccess();
        } catch (err) {
            const errorTitle = err?.error?.response?.data?.detail || 'Ошибка';

            const defaultMessage = `${err?.error?.response?.status} ${err?.error?.response?.statusText}`;
            const errorMessage = formatError(err?.error?.response?.data?.messages, defaultMessage);

            setInfoPopup({
                open: true,
                content: errorMessage,
                title: errorTitle,
            });
        }
    };

    const PossibleDisableCustomAutocomplete = transformToPossibleDisabledItem(
        CustomAutocomplete,
        'route',
        disableList,
        'disabled'
    );

    // const LocationDisableCustomAutocomplete = transformToPossibleDisabledItem(
    //     CustomAutocomplete,
    //     'location',
    //     [],
    //     'disabled'
    // );

    const initialState = getInitialState(route);

    const renderRow = () => {
        return (
            <Form.List name='services'>
                {(fields, { add, remove }) => (
                    <>
                        {fields?.map((field) => {
                            const serviceRule = [
                                {
                                    // required: true,
                                    type: 'string',
                                    message: '2',
                                },
                                (form) => {
                                    const service =
                                        form.getFieldsValue()?.services?.[field.name]?.service;

                                    return {
                                        message: '1',
                                        type: 'string',
                                        validator() {
                                            if (!service) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        },
                                    };
                                },
                            ];

                            const workersOnServiceRule = [
                                (form) => {
                                    const workers = Number(
                                        form.getFieldsValue()?.services?.[field.name]?.workers || 0
                                    );

                                    return {
                                        message: 'Укажите кол-во часов',
                                        type: 'boolean',
                                        required: true,
                                        validator() {
                                            if (workers) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject();
                                        },
                                    };
                                },
                            ];

                            return (
                                <Row gutter={16} key={field.key}>
                                    <Col span={11}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                                prevValues.services !== curValues.services
                                            }
                                            rules={serviceRequiredRule}
                                        >
                                            {() => (
                                                <FormItem
                                                    label={nameItemsList.service}
                                                    name={[field.name, 'service']}
                                                    required
                                                    rules={serviceRule}
                                                >
                                                    <CustomAutocomplete
                                                        disabled={!form.getFieldsValue().location}
                                                        options={servicesList}
                                                        onChange={() => {}}
                                                    />
                                                </FormItem>
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <FormItem
                                            label={nameItemsList.workers}
                                            name={[field.name, 'workers']}
                                            rules={workersOnServiceRule}
                                        >
                                            <NumberInput
                                                disabled={!form.getFieldsValue().location}
                                                className={clsx(
                                                    styles.form_input,
                                                    styles.withBgDisabled
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={5}>
                                        <FormItem
                                            label={nameItemsList.expected_hours}
                                            name={[field.name, 'expected_hours']}
                                            noWrap
                                            // rules={workersOnServiceHoursRule}
                                        >
                                            <NumberInput
                                                disabled={!form.getFieldsValue().location}
                                                className={clsx(
                                                    styles.form_input,
                                                    styles.withBgDisabled
                                                )}
                                            />
                                        </FormItem>
                                    </Col>

                                    <Col span={2}>
                                        <div
                                            className={styles.buttonsContainerIconDelete}
                                            onClick={() => remove(field.name)}
                                        >
                                            <img
                                                alt='icon'
                                                src='/delete_icon.svg'
                                                className={styles.buttonsContainerIcon1}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}

                        <div
                            onClick={() => {
                                if (locationId === -1) return;

                                add();
                            }}
                            className={styles.buttonsContainerItem}
                        >
                            <img
                                alt='icon'
                                src='/add_icon.svg'
                                className={styles.buttonsContainerIcon1}
                            />
                            <div>Добавить услугу</div>
                        </div>
                    </>
                )}
            </Form.List>
        );
    };

    const has_address = form.getFieldsValue()?.has_address;

    useEffect(() => {
        if (has_address) {
            const address =
                locationId && locationId !== -1
                    ? locationsList.find((i) => i.id === locationId)?.address
                    : undefined;

            form.setFieldsValue({
                ...form.getFieldsValue(),
                address: address
                    ? {
                          value: address,
                      }
                    : undefined,
            });
        }
    }, [locationId, has_address]);

    const onChangeCheckbox = (e) => {
        const address =
            locationId && locationId !== -1
                ? locationsList.find((i) => i.id === locationId)?.address
                : undefined;

        form.setFieldsValue({
            ...form.getFieldsValue(),
            has_address: e.target.checked,
            address:
                e.target.checked && address
                    ? {
                          value: address,
                      }
                    : undefined,
        });
    };

    const handlePreview = async (file) => {
        if (!file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        file.status = 'success';
    };

    const handleChange = (data) => setUploadedImages(data.fileList);

    const onTimeChanged = () => {
        if (form.getFieldValue('time')) {
            setTimeInputActive('time');
        } else if (form.getFieldValue('time_interval')) {
            setTimeInputActive('time_interval');
        } else setTimeInputActive(null);
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {platform === 'mobile' ? (
                <NewRequestMobilePopup
                    mainForm={form}
                    routeId={routeId}
                    submitForm={submitForm}
                    initialState={initialState}
                    datePopupOpen={datePopupOpen}
                    setDatePopupOpen={setDatePopupOpen}
                    timePopupOpen={timePopupOpen}
                    setTimePopupOpen={setTimePopupOpen}
                    oneTimeInput={oneTimeInput}
                    setOneTimeInput={setOneTimeInput}
                    disableList={disableList}
                    routeOptions={routeOptions}
                    setRouteId={setRouteId}
                    route={route}
                    locationsList={locationsList}
                    setLocationId={setLocationId}
                    locationId={locationId}
                    servicesList={servicesList}
                    infoPopup={infoPopup}
                    setInfoPopup={setInfoPopup}
                    close={close}
                />
            ) : (
                <>
                    <Form
                        labelWrap
                        layout='horizontal'
                        form={form}
                        onFinish={submitForm}
                        initialValues={initialState}
                    >
                        <ShortPopup
                            open={datePopupOpen}
                            onClose={() => setDatePopupOpen(false)}
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <FormItem
                                    noStyle
                                    name='date'
                                    valuePropName='date'
                                    rules={requiredRule}
                                >
                                    <Calendar
                                        onChange={() => {
                                            close();
                                        }}
                                        locale={locales.ru}
                                        color='#000000'
                                        rangeColors={['#000000']}
                                    />
                                </FormItem>
                            )}
                        </ShortPopup>

                        {/* <ShortPopup */}
                        {/*    open={timePopupOpen} */}
                        {/*    onClose={() => setTimePopupOpen(false)} */}
                        {/*    modal */}
                        {/*    closeOnDocumentClick */}
                        {/* > */}
                        {/*    {(close) => ( */}
                        {/*        <Form.Item */}
                        {/*            noStyle */}
                        {/*            name={oneTimeInput ? 'time' : 'time_interval'} */}
                        {/*            rules={timeRequiredRule} */}
                        {/*        > */}
                        {/*            <TimePicker */}
                        {/*                close={close} */}
                        {/*                onlyOneValue={oneTimeInput} */}
                        {/*                onChange={() => { */}
                        {/*                    if (oneTimeInput) { */}
                        {/*                        form.setFields([ */}
                        {/*                            { name: 'time_interval', value: [null, null] }, */}
                        {/*                        ]); */}
                        {/*                    } else { */}
                        {/*                        form.setFields([ */}
                        {/*                            { name: 'time', value: [null, null] }, */}
                        {/*                        ]); */}
                        {/*                    } */}
                        {/*                }} */}
                        {/*            /> */}
                        {/*        </Form.Item> */}
                        {/*    )} */}
                        {/* </ShortPopup> */}

                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <FormItem
                                        rules={requiredRule}
                                        label={nameItemsList.date}
                                        name='date'
                                        valuePropName='date'
                                    >
                                        <DateViewer
                                            setPopupOpen={setDatePopupOpen}
                                            routeId={routeId}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label='Время' name='time' rules={timeRequiredRule}>
                                        <InputMask
                                            disabled={timeInputActive === 'time_interval'}
                                            onChange={onTimeChanged}
                                            mask='99:99'
                                            placeholder='00:00'
                                            className={`ant-input ant-input-lg ${styles.formInput}`}
                                        />
                                    </FormItem>
                                    {/* <FormItem */}
                                    {/*    label='Время' */}
                                    {/*    name='time' */}
                                    {/*    required */}
                                    {/*    rules={timeRequiredRule} */}
                                    {/* > */}
                                    {/*    <TimePopup */}
                                    {/*        setPopupOpen={(v) => { */}
                                    {/*            setOneTimeInput(true); */}
                                    {/*            setTimePopupOpen(v); */}
                                    {/*        }} */}
                                    {/*        onlyOneValue */}
                                    {/*    /> */}
                                    {/* </FormItem> */}
                                </Col>
                                <Col>
                                    <div className={styles.or}>ИЛИ</div>
                                </Col>

                                <Col span={7}>
                                    <FormItem
                                        label='Интервал'
                                        name='time_interval'
                                        rules={timeRequiredRule}
                                        // rules={requiredRule}
                                    >
                                        <InputMask
                                            disabled={timeInputActive === 'time'}
                                            onChange={onTimeChanged}
                                            mask='99:99-99:99'
                                            placeholder='00:00-00:00'
                                            className={`ant-input ant-input-lg ${styles.formInput}`}
                                        />
                                    </FormItem>
                                    {/* <FormItem */}
                                    {/*    label='Интервал' */}
                                    {/*    name='time_interval' */}
                                    {/*    required */}
                                    {/*    rules={timeRequiredRule} */}
                                    {/* > */}
                                    {/*    <TimePopup */}
                                    {/*        setPopupOpen={(v) => { */}
                                    {/*            setOneTimeInput(false); */}
                                    {/*            setTimePopupOpen(v); */}
                                    {/*        }} */}
                                    {/*    /> */}
                                    {/* </FormItem> */}
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem
                                        label={nameItemsList.location}
                                        rules={requiredRule}
                                        name='location'
                                    >
                                        <CustomAutocomplete
                                            // name={'location'}
                                            options={locationsList}
                                            onIdChange={setLocationId}
                                            defaultId={locationId ?? -1}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            {renderRow()}
                        </Form.Item>

                        <FormItem
                            label={nameItemsList.shipment_type}
                            name='shipment_type'
                            rules={requiredRule}
                        >
                            <Input className={styles.form_input} />
                        </FormItem>
                        <Row gutter={16}>
                            <Col span={3}>
                                <FormItem
                                    label={nameItemsList.address}
                                    rules={requiredRule}
                                    name='has_address'
                                    valuePropName='checked'
                                >
                                    <Checkbox
                                        onChange={onChangeCheckbox}
                                        className={styles.form_checkbox}
                                        type='checkbox'
                                        defaultChecked={false}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={21}>
                                <FormItem label=' ' name='address'>
                                    <AddressSuggestions
                                        defaultQuery={route?.address}
                                        token={DADATA_API_KEY}
                                        containerClassName={styles.addressSuggestions}
                                        customInput={Input}
                                        inputProps={{
                                            className: styles.form_input,
                                        }}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <FormItemDependentOnRoute
                            label={nameItemsList.driver}
                            name='driver'
                            routeId={routeId}
                        />

                        <FormItemDependentOnRoute
                            label={nameItemsList.phones}
                            name='phones'
                            routeId={routeId}
                        />

                        <Collapse className={styles.collapse}>
                            <Collapse.Panel
                                header='Дополнительные поля (при необходимости)'
                                key='1'
                            >
                                <AutoFillItem form={form} routeId={routeId} />

                                <FormItem label={nameItemsList.route} name='route'>
                                    <PossibleDisableCustomAutocomplete
                                        options={routeOptions}
                                        onIdChange={setRouteId}
                                        defaultId={route?.routeId || -1}
                                    />
                                </FormItem>

                                <Col span={8}>
                                    <FormItem
                                        label={nameItemsList.index}
                                        name='index'
                                        notInfoMessage
                                    >
                                        <Input className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Form.Item noStyle>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <FormItem label={nameItemsList.volume} name='volume'>
                                                <NumberInput className={styles.form_input} />
                                            </FormItem>
                                        </Col>

                                        <Col span={6}>
                                            <FormItem label={nameItemsList.mass} name='mass'>
                                                <NumberInput className={styles.form_input} />
                                            </FormItem>
                                        </Col>

                                        <Col span={6}>
                                            <FormItem label={nameItemsList.places} name='places'>
                                                <NumberInput className={styles.form_input} />
                                            </FormItem>
                                        </Col>

                                        <Col span={6}>
                                            <FormItem
                                                label={nameItemsList.workers_required}
                                                name='workers_required'
                                            >
                                                <NumberInput className={styles.form_input} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item noStyle>
                                    <Row gutter={16}>
                                        <Col span={7}>
                                            <FormItem
                                                label={nameItemsList.max_size}
                                                name='max_size'
                                            >
                                                <Input className={styles.form_input} />
                                            </FormItem>
                                        </Col>

                                        <Col span={7}>
                                            <FormItem label={nameItemsList.floor} name='floor'>
                                                <Input className={styles.form_input} />
                                            </FormItem>
                                        </Col>

                                        <Col span={7}>
                                            <FormItem
                                                label={nameItemsList.carrying_distance}
                                                name='carrying_distance'
                                            >
                                                <Input className={styles.form_input} />
                                            </FormItem>
                                        </Col>

                                        <Col span={3}>
                                            <FormItem
                                                label={nameItemsList.has_elevator}
                                                name='has_elevator'
                                                valuePropName='checked'
                                            >
                                                <Checkbox
                                                    className={styles.form_checkbox}
                                                    type='checkbox'
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Collapse.Panel>
                            <Collapse.Panel header='Фото' key='2'>
                                <div className={styles.uploadPhotoContainer}>
                                    <Form.Item name='photos'>
                                        <Upload
                                            fileList={uploadedImages}
                                            name='file'
                                            listType='picture-card'
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            multiple
                                        >
                                            Добавить
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </Collapse.Panel>
                        </Collapse>

                        <FormControls />
                    </Form>

                    <ShortPopup
                        modal
                        closeOnDocumentClick
                        open={infoPopup.open}
                        onClose={() => {
                            if (infoPopup.title === 'Результат') {
                                close();
                            } else {
                                setInfoPopup({ ...infoPopup, open: false });
                            }
                        }}
                    >
                        {(close) => (
                            <PopupInfo title={infoPopup.title} close={close}>
                                {infoPopup.content}
                            </PopupInfo>
                        )}
                    </ShortPopup>
                </>
            )}
        </>
    );
};
