import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const fetchReports = fetch(
    'FETCH_REPORTS',
    'gt/customer/reports/',
    {},
    undefined,
    showError
);
export const confirmReport = fetch(
    'CONFIRM_REPORT',
    'gt/customer/confirm_report/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
