import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const accountInfo = fetch(
    'ACCOUNT_INFO',
    'gt/customer/account_info/',
    {},
    undefined,
    showError
);
