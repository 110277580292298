import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { setOpenPlatform } from '@store/actions';
import { isOpenPlatformSelector, platformSelector } from '@store/reducers/platform';

import styles from './styles.module.scss';

const reportsCallback = ({ info }) =>
    !!info?.new_reports_count && (
        <div className={styles.reportsCount}>{info.new_reports_count}</div>
    );

const _LEFT_MENU_MOBILE = [
    { id: 'requests', title: 'Заявки', icon: '/request_icon.svg', info_required: true },
    {
        id: 'requests_on_map',
        title: 'Заявки на карте',
        icon: '/requests_on_map_icon.svg',
        info_required: true,
    },
    { id: 'info', title: 'Инфо', icon: '/info_icon.svg', info_required: false },
];

const _LEFT_MENU = [
    {
        id: 'requests_2',
        title: 'Список V2.0',
        icon: '/request_icon.svg',
        info_required: true,
    },
    {
        id: 'requests',
        title: 'Список',
        icon: '/request_icon.svg',
        info_required: true,
    },
    {
        id: 'requests_calendar',
        title: 'Шахматка',
        icon: '/requests_calendar.svg',
        info_required: true,
    },
    {
        id: 'requests_on_map',
        title: 'На карте',
        icon: '/requests_on_map_icon.svg',
        info_required: true,
    },
    {
        id: 'imports',
        title: 'История импорта',
        icon: '/import_page_icon.svg',
        info_required: true,
        callback: (userInfo) => {
            if (userInfo.importsUpdated) {
                return <img alt='icon' src='/info_fill_icon.svg' className={styles.icon} />;
            }

            return null;
        },
    },
    {
        id: 'reports',
        title: 'Взаиморасчеты',
        icon: '/reports_icon.svg',
        info_required: true,
        callback: reportsCallback,
    },
    { id: 'balance', title: 'Баланс', icon: '/balance_icon.svg', info_required: true },
    { id: 'info', title: 'Инфо', icon: '/info_icon.svg', info_required: false },
];

const _LEFT_MENU_MOBILE_DISABLE_LK_PAGES = [
    { id: 'requests_2', title: 'Заявки', icon: '/request_icon.svg', info_required: true },
];

const _LEFT_MENU_DISABLE_LK_PAGES = [
    {
        id: 'requests_2',
        title: 'Список V2.0',
        icon: '/request_icon.svg',
        info_required: true,
    },
];

const MenuItem = ({ item, user, onClick, activeClass }) => {
    const ItemWrapper = item.id
        ? ({ children }) => (
              <Link
                  key={item.id}
                  to={`/${item.id}`}
                  className={styles.menuItem + activeClass}
                  onClick={onClick}
              >
                  {children}
              </Link>
          )
        : ({ children }) => <div className={styles.menuItem + activeClass}>{children}</div>;
    return (
        <ItemWrapper>
            <img alt='icon' src={item.icon} className={styles.icon} />
            <div className={styles.text}>
                {item.title} {item?.callback?.(user.info) || null}
            </div>
        </ItemWrapper>
    );
};

export const Sidebar = ({ menu }) => {
    const dispatch = useDispatch();
    const disable_lk_pages = useSelector((state) => state.user.info.disable_lk_pages);
    const user = useSelector((state) => state.user);
    const platform = useSelector(platformSelector);
    const isOpen = useSelector(isOpenPlatformSelector);

    const LEFT_MENU_MOBILE = useMemo(
        () => (disable_lk_pages ? _LEFT_MENU_MOBILE_DISABLE_LK_PAGES : _LEFT_MENU_MOBILE),
        [disable_lk_pages]
    );
    const LEFT_MENU = useMemo(
        () => (disable_lk_pages ? _LEFT_MENU_DISABLE_LK_PAGES : _LEFT_MENU),
        [disable_lk_pages]
    );

    const close = () => {
        dispatch(setOpenPlatform(false));
    };

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isClosed, setIsClosed] = useState(true);

    const resizeEvent = () => {
        // eslint-disable-next-line no-restricted-globals
        const st = screen.top || screen.availTop || window.screenTop;

        if (st !== window.screenY) {
            setIsFullscreen(false);
            return;
        }

        setIsFullscreen(
            window.fullScreen === true ||
                // eslint-disable-next-line no-restricted-globals
                screen.height - document.documentElement.clientHeight <= 30
        );
    };

    useEffect(() => {
        window.addEventListener('resize', resizeEvent);

        return () => {
            window.removeEventListener('resize', resizeEvent);
        };
    }, []);

    const resizeEventCloseSidebar = () => {
        const w = window.innerWidth;
        setIsClosed(w <= 1900);
    };

    useLayoutEffect(() => {
        resizeEventCloseSidebar();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', resizeEventCloseSidebar);

        return () => {
            window.removeEventListener('resize', resizeEventCloseSidebar);
        };
    }, []);

    const clickRequestFullscreen = () => {
        const elem = document?.documentElement;
        if (elem?.requestFullscreen) {
            setIsFullscreen(true);
            elem?.requestFullscreen();
        }
    };

    if (!isOpen && platform === 'mobile' && !isFullscreen)
        return (
            <div onClick={clickRequestFullscreen} className={styles.fullscreen}>
                На весь экран
            </div>
        );

    if (!isOpen && platform === 'mobile') return null;

    return (
        <div
            className={clsx(styles.wrapper, {
                [styles.mobile]: platform === 'mobile',
                [styles.isClosed]: isClosed,
            })}
        >
            {platform === 'mobile' && (
                <div className={styles.close} onClick={close}>
                    <img alt='icon' src='/close_icon.svg' />
                </div>
            )}
            <div>
                <img className={styles.logo} alt='logo' src='/logo.svg' />
            </div>

            <div className={styles.menu}>
                {(platform === 'mobile' ? LEFT_MENU_MOBILE : LEFT_MENU).map((menuItem, index) => {
                    return !menuItem.children ? (
                        <MenuItem
                            key={index}
                            item={menuItem}
                            activeClass={menuItem.id === menu ? ` ${styles.active}` : ''}
                            user={user}
                            onClick={close}
                        />
                    ) : (
                        <div key={index}>
                            <MenuItem
                                item={menuItem}
                                activeClass={
                                    isClosed &&
                                    menuItem.children.some((childItem) => childItem.id === menu)
                                        ? ` ${styles.active}`
                                        : ''
                                }
                                user={user}
                                onClick={close}
                            />
                            <div className={styles.childrensWrapper}>
                                {menuItem.children.map((childItem, i) => (
                                    <MenuItem
                                        key={i}
                                        item={childItem}
                                        activeClass={`${
                                            childItem.id === menu ? ` ${styles.active}` : ''
                                        } ${styles.childMenuItem}`}
                                        user={user}
                                        onClick={close}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>

            {platform === 'desktop' && (
                <>
                    <div className={styles.paymentInfo}>
                        <div>Не оплачено</div>
                        <div>
                            <b>{user.info.unpaid_requests_total}</b> заявок
                        </div>
                        <div>
                            на сумму{' '}
                            <div className={styles.price}>
                                {user.info.unpaid_requests_sum}&nbsp;&#8381;
                            </div>
                        </div>
                        <br />
                        <div>Из них:</div>
                        <div>
                            - в оплате <b>{user.info.unpaid_requests_in_payment}</b>
                        </div>
                        <div>
                            - не согласованы <b>{user.info.unpaid_requests_active}</b>
                        </div>
                    </div>
                    <div className={styles.socials}>
                        <img alt='icon' src='/facebook_icon.svg' className={styles.icon} />
                        <a
                            href='https://www.linkedin.com/company/gettask/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img alt='icon' src='/linkedin_icon.svg' className={styles.icon} />
                        </a>
                    </div>
                </>
            )}
            <div className={styles.copyright}>GetTask © 2024</div>
        </div>
    );
};
