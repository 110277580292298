import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Space } from 'antd';
import moment from 'moment';

import {
    ButtonsContainer,
    CustomSelectWithStorage,
    DatePickerApply,
    SearchWrapper,
    Wrapper,
} from '@components';
import { fetchLocation, fetchRequests, setLastStateSearch } from '@store/actions';
import { REQUESTS_PREFIX, storageService } from '@utils';

import { CalendarTable } from '../../components/CalendarTable';
import { platformSelector } from '../../store/reducers/platform';
import CreateReqModal from '../HomePage/CreateReqModal';
import { formatDate } from '../HomePage/utils';

import styles from './styles.module.scss';

const localStorageService = storageService(REQUESTS_PREFIX);

export const RequestsCalendarPage = () => {
    const { requestsList, allow_requests_creation, show_locations_filter } = useSelector(
        (state) => ({
            requestsList: state.requests.list,
            dashboard: state.requests.dashboard,
            sorting: state.requests.sorting,
            filterStatus: state.requests.filterStatus,
            isRequestsLoading: state.requests.isLoading,
            allow_requests_creation: state.user.info.allow_requests_creation,
            show_locations_filter: state.user.info.show_locations_filter,
        })
    );
    const [requestPopup, setRequestPopup] = useState({
        open: false,
        route: null,
        disable: null,
        data: null,
    });
    const [loading, setLoading] = useState(false);
    const platform = useSelector(platformSelector);
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [branch, setBranch] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();

    const getRequests = useCallback(async () => {
        setLoading(true);
        const res = await dispatch(
            fetchLocation({
                forward: JSON.stringify({
                    first_day: moment(localStorageService.get('startRanges')).format('DD.MM.YYYY'),
                    last_day: moment(localStorageService.get('endRanges')).format('DD.MM.YYYY'),
                }),
            })
        );

        setBranch(res.payload.data.results.map((r) => ({ id: +r.id, text: r.text })));
        const result = await dispatch(
            fetchRequests({
                search_text: localStorageService.get('search'),
                location: localStorageService.get('branch'),
                first_day: moment(localStorageService.get('startRanges')).format('DD.MM.YYYY'),
                last_day: moment(localStorageService.get('endRanges')).format('DD.MM.YYYY'),
            })
        );
        setLoading(false);
        return result;
    }, []);

    const requestInfo = (request) => {
        dispatch(
            setLastStateSearch({
                curReq: `${request.pk} ${request.status.text}`,
                location: request.location.name,
                createdRequestDate: moment(request.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            })
        );

        history.push('/home');
    };
    const createRequest = (location, date) => {
        setRequestPopup({
            open: true,
            route: null,
            disable: null,
            data: { location, date },
        });
        if (!allow_requests_creation) {
            setInfoPopup({
                open: true,
                content: 'Пополните баланс для подачи заявок',
                title: 'Недостаточно средств',
            });
        }
    };
    function getFormattedRequests() {
        const formatted_requests = [];
        requestsList.forEach((request) => {
            const current_location = formatted_requests.find(
                (item) => item.location?.id === request.location.id && !item.requests[request.date]
            );
            if (!current_location)
                formatted_requests.push({
                    location: request.location,
                    requests: { [request.date]: request },
                });
            else current_location.requests[request.date] = request;
        });
        return formatted_requests;
    }
    const onCloseNewRequestPopup = () => {
        setRequestPopup({ open: false, route: null, disable: null, data: null });
    };
    useEffect(() => {
        if (!localStorageService.get('startRanges') || !localStorageService.get('endRanges')) {
            localStorageService.set('startRanges', moment().startOf('week').format());
            localStorageService.set('endRanges', moment().endOf('week').format());
        }
        getRequests();
    }, []);
    return (
        <Wrapper title='Шахматка'>
            <ButtonsContainer
                classes={styles.mediaStyle}
                left={
                    <Space size='middle'>
                        <DatePickerApply
                            localStorageService={localStorageService}
                            defaultRangeStart={moment().startOf('week').toDate()}
                            defaultRangeEnd={moment().endOf('week').toDate()}
                            updateData={getRequests}
                        />
                        {show_locations_filter && (
                            <CustomSelectWithStorage
                                options={branch.map(({ text, id }) => ({
                                    label: text,
                                    value: id,
                                }))}
                                placeholder='Филиал'
                                localStorageService={localStorageService}
                                updateData={getRequests}
                                optionName='branch'
                                size='large'
                                allowClear
                                width={180}
                            />
                        )}
                    </Space>
                }
                right={
                    platform === 'desktop' && (
                        <Space size='middle'>
                            <SearchWrapper
                                placeHolder='Найти заявку'
                                localStorageService={localStorageService}
                                updateData={getRequests}
                                showItemsOnPage={false}
                                searchClassName={styles.noIndent}
                            />
                        </Space>
                    )
                }
            />
            <CalendarTable
                data={getFormattedRequests()}
                date_range={[
                    localStorageService.get('startRanges'),
                    localStorageService.get('endRanges'),
                ]}
                isLoading={loading}
                pagination={null}
                createRequestAction={createRequest}
                openRequestAction={requestInfo}
            />
            <CreateReqModal
                onSubmit={(values) => {
                    setRequestPopup({ ...requestPopup, open: false });
                    dispatch(
                        setLastStateSearch({
                            ...values,
                            temporarySubmitData: { ...values, date1: formatDate(values.date1) },
                            curReq: '',
                            location: values.location.text,
                            createdRequestDate: '',
                        })
                    );

                    history.push('/home');
                }}
                infoPopup={infoPopup}
                setInfoPopup={setInfoPopup}
                isOpen={requestPopup.open}
                onClose={onCloseNewRequestPopup}
                data={requestPopup.data}
            />
        </Wrapper>
    );
};
