import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const fetchRequests = fetch(
    'FETCH_REQUESTS',
    'gt/customer/v2/delivery/request/',
    {},
    undefined,
    showError
);
export const fetchRequestsOnMap = fetch(
    'FETCH_REQUESTS_ON_MAP',
    'gt/customer/v2/delivery/map/request/',
    {},
    undefined,
    showError
);
export const fetchRequestsOnMapAutocomplete = fetch(
    'FETCH_REQUESTS_ON_MAP_AUTOCOMPLETE',
    'gt/customer/v2/delivery/map/autocomplete/request/',
    {},
    undefined,
    showError
);
export const updateRequest = (payloadSuccess) =>
    fetch(
        'UPDATE_REQUEST',
        'gt/customer/v2/delivery/request/update/',
        {
            method: 'POST',
        },
        undefined,
        showError,
        payloadSuccess
    );
export const updateAdressRequest = fetch(
    'UPDATE_REQUEST',
    'gt/customer/v2/delivery/request/item/update/',
    {
        method: 'POST',
    },
    undefined,
    showError
);

export const updateSlotRequest = (id, onSuccess, onError) =>
    fetch(
        'UPDATE_SLOT',
        `gt/customer/v2/delivery/request/update/worker_slot/${id}/`,
        {
            method: 'PUT',
        },
        onSuccess,
        onError
    );

export const cancelRequest = fetch(
    'CANCEL_REQUEST',
    'gt/customer/cancel_request/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
export const removeRequest = fetch(
    'REMOVE_REQUEST',
    'gt/customer/remove_request/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
export const createRequest = fetch(
    'CREATE_REQUEST',
    'gt/customer/v2/delivery/request/create/',
    {
        method: 'POST',
    }
    // undefined,
    // showError
);

export const AddItemPhoto = (id) =>
    fetch(
        'ADD_ITEM_PHOTO',
        `gt/customer/v2/delivery/request/item/add_item_photo/${id}/`,
        {
            method: 'POST',
        },
        undefined,
        showError
    );

export const addAddressToRequest = fetch(
    'CREATE_REQUEST',
    'gt/customer/v2/delivery/request/item/create/',
    {
        method: 'POST',
    },
    undefined,
    showError
);

export const moveItem = fetch(
    'MOVE_ITEM',
    'gt/customer/move_item/',
    { method: 'POST' },
    undefined,
    showError
);
export const removeItem = fetch(
    'REMOVE_ITEM',
    'gt/customer/remove_item/',
    { method: 'POST' },
    undefined,
    showError
);
export const copyItem = fetch(
    'COPY_ITEM',
    'gt/customer/copy_item/',
    { method: 'POST' },
    undefined,
    showError
);
export const fetchLocation = fetch(
    'FETCH_LOCATIONS',
    'gt/customer/location_autocomplete/',
    {},
    undefined,
    showError
);
export const fetchServices = fetch(
    'FETCH_SERVICES',
    'gt/customer/customer_service_autocomplete/',
    {},
    undefined,
    showError
);

export const calculateServicesCost = fetch(
    'CALCULATE_SERVICES',
    'gt/customer/v2/service/cost/',
    {
        method: 'POST',
    },
    undefined,
    showError
);

export const fetchExistingCards = fetch(
    'FETCH_CARDS',
    'gt/customer/v2/delivery/individual_entity/',
    {
        method: 'GET',
    }
    // undefined,
    // showError
);

export const createCard = fetch(
    'CREATE_CARD',
    'gt/customer/v2/delivery/individual_entity/card/',
    {
        method: 'POST',
    },
    undefined,
    showError
);

export const deleteCard = fetch(
    'DELETE_CARD',
    'gt/customer/v2/delivery/individual_entity/card/',
    {
        method: 'DELETE',
    },
    undefined,
    showError
);

export function setFilter(filterStatus) {
    return {
        type: 'UPDATE_REQUESTS_FILTER',
        payload: { filterStatus },
    };
}

export function setSorting(sorting) {
    return {
        type: 'UPDATE_REQUESTS_SORTING',
        payload: { sorting },
    };
}

export function setUpdateRequests(results) {
    return {
        type: 'FETCH_SERVICES_SUCCESS',
        payload: { data: { results } },
    };
}

export function setLastStateSearch(stateSearch) {
    return {
        type: 'SET_LAST_STATE_SEARCH',
        payload: stateSearch,
    };
}
