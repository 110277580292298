import { useEffect, useMemo, useState } from 'react';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { Button, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import styled from 'styled-components';

import { CustomAutocomplete, FormItem, PopupInfo } from '@components';

import { DateViewer } from '../../../components/NewRequestPopup/DateViewer';
import { fetchLocation, fetchServices } from '../../../store/actions';

import styles from '../../RequestsPage/styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        flex: 0 0 0 !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

const CreateReqModal = ({ isOpen, onClose, onSubmit, setInfoPopup, infoPopup, data }) => {
    const dispatch = useDispatch();
    const [dtPopupOpen, setDtPopupOpen] = useState(false);
    const [form] = useForm();
    const [firstDate, setFirstDate] = useState(form.getFieldValue('date1'));
    const [, setIsNeedUpdate] = useState(false);
    const locationsListState = useSelector((state) => state.locations?.list);
    const locationsList = useMemo(
        () =>
            locationsListState.map((i) => ({
                ...i,
                text: i.text + (i.type ? ` - ${i.type}` : ''),
            })),
        [locationsListState]
    );
    const [location, setLocation] = useState(form.getFieldValue('location'));
    useEffect(() => {
        const updateLocation = async () => {
            if (data?.location && data?.location?.id) {
                const location = locationsList.find((i) => i.id === data.location.id.toString());
                setLocation(location);
                form.setFieldsValue({ location });
            }
        };
        updateLocation();
        if (data?.date) {
            form.setFieldsValue({ date1: data.date });
            setFirstDate(data.date);
        }
    }, [data]);

    useEffect(() => {
        const request = async () => {
            await dispatch(fetchLocation({}));
        };
        request();
    }, []);

    useEffect(() => {
        if (location) {
            const updateServicesOptions = async () => {
                if (location === -1) return;
                dispatch(
                    fetchServices({
                        locationId: location.id,
                    })
                );
            };
            updateServicesOptions();
        }
    }, [location]);

    useEffect(() => {
        if (locationsList && !location && locationsList?.length === 1) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                location: locationsList[0],
            });
            setLocation(locationsList[0]);
            setIsNeedUpdate((prev) => !prev);
        }
    }, [locationsList, location]);

    const handleClose = () => {
        form.resetFields();
        onClose();
    };
    const requiredRule = [{ required: true, message: '' }];
    return (
        <Modal
            destroyOnClose
            visible={isOpen}
            title='Новая заявка'
            footer={null}
            onCancel={handleClose}
            className={styles.modal}
        >
            <Form form={form} onFinish={onSubmit} layout='vertical'>
                <FormItem
                    name='date1'
                    label='Дата'
                    rules={requiredRule}
                    isLabelVertical
                    disabled={false}
                >
                    <DateViewer
                        date={form.getFieldValue('date1') || firstDate}
                        setPopupOpen={() => setDtPopupOpen(true)}
                        routeId={-1}
                    />
                    <ShortPopup
                        modal
                        open={dtPopupOpen}
                        onClose={() => setDtPopupOpen(false)}
                        closeOnDocumentClick
                    >
                        {(close) => {
                            return (
                                <Form.Item name='date2' valuePropName='date'>
                                    <Calendar
                                        onChange={(v) => {
                                            form.setFieldsValue({ date1: v });
                                            close();
                                        }}
                                        locale={locales.ru}
                                        color='#000000'
                                        rangeColors={['#000000']}
                                    />
                                </Form.Item>
                            );
                        }}
                    </ShortPopup>
                </FormItem>

                <Form.Item
                    shouldUpdate
                    name='location'
                    label='Филиал'
                    dependencies={['location']}
                    disabled={false}
                    rules={requiredRule}
                >
                    <CustomAutocomplete
                        onChange={(v) => {
                            const location = locationsList?.find(({ text }) => text === v);
                            form.setFieldsValue({
                                location,
                            });
                            setLocation(location);
                            setIsNeedUpdate((prev) => !prev);
                        }}
                        customValue={location?.text}
                        options={locationsList}
                    />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button type='primary' htmlType='submit'>
                        Создать
                    </Button>
                    <Button type='ghost' onClick={handleClose}>
                        Отменить
                    </Button>
                </div>
                <ShortPopup
                    modal
                    closeOnDocumentClick
                    open={infoPopup.open}
                    onClose={() => {
                        setInfoPopup({ ...infoPopup, open: false });
                    }}
                >
                    {(close) => (
                        <PopupInfo title={infoPopup.title} close={close}>
                            {infoPopup.content}
                        </PopupInfo>
                    )}
                </ShortPopup>
            </Form>
        </Modal>
    );
};

export default CreateReqModal;
