import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const updateAddressOptions = fetch(
    'UPDATE_ADDRESS_OPTIONS',
    'gt/customer/item_autocomplete/',
    {},
    undefined,
    showError
);
