import { message } from 'antd';

export const showError = (error) => {
    if (typeof error === 'string') {
        message.error({ content: error });
    }
    if (
        typeof error !== 'string' &&
        (error?.response?.data?.detail || error.response?.data?.messages)
    ) {
        const errorText = error.response.data.detail ? [error.response.data.detail] : [];
        if (error.response.data.messages) {
            const isArray = error.response.data.messages.length;
            Object.keys(error.response.data.messages).forEach((key) => {
                const errorData = error.response.data.messages[!isArray ? key : Number(key)];
                errorText.push(
                    `${!isArray ? key : ''}${!isArray ? ':' : ''}${
                        typeof errorData !== 'object' ? errorData : String(Object.values(errorData))
                    }`
                );
            });
        }
        message.error({
            content: (
                <>
                    {errorText.map((text) => (
                        <div key={text}>{text}</div>
                    ))}
                </>
            ),
        });
    }
};
