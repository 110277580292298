import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const fetchInvoices = fetch(
    'FETCH_INVOICES',
    'gt/customer/invoices/',
    {},
    undefined,
    showError
);
