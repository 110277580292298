import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const fetchContacts = fetch(
    'FETCH_CONTACTS',
    'gt/customer/contact_persons/',
    {},
    undefined,
    showError
);
export const updateContacts = fetch(
    'UPDATE_CONTACTS',
    'gt/customer/update_contact_person/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
export const addContact = fetch(
    'ADD_CONTACT',
    'gt/customer/add_contact_person/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
