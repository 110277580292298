import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import styles from './styles.module.scss';

const LongPopup = styled(Popup)`
    &-content {
        margin: 4% auto auto !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
    &-overlay {
        position: fixed !important;
        height: 100vh !important;
    }
`;

export const ViewButton = ({ columns, setColumns, localStorageField, initColumns }) => {
    const [columnsCopy, setColumnsCopy] = React.useState({ ...columns });

    const onClickSave = () => {
        setColumns(columnsCopy);
        let columns = {};

        for (const key in columnsCopy) {
            const element = columnsCopy[key];
            const initElement = initColumns[key];

            if (element.isVisible !== initElement.isVisible) {
                columns = { ...columns, [key]: element };
            }
        }
        localStorage.setItem(localStorageField, JSON.stringify(columns));
    };

    return (
        <LongPopup
            trigger={
                <div className={styles.view}>
                    <img alt='icon' src='/view_icon.svg' />
                    <div>Вид</div>
                </div>
            }
            modal
            closeOnDocumentClick
        >
            {(close) => (
                <div className={styles.viewPopup}>
                    <div onClick={close} className={styles.viewPopupClose}>
                        <img alt='icon' src='/close_icon.svg' />
                    </div>
                    <div className={styles.viewPopupTitle}>Вид</div>
                    <div className={styles.columns}>
                        {Object.keys(columnsCopy).map((c) => (
                            <div key={c}>
                                <label className={styles.checkboxContainer}>
                                    <input
                                        type='checkbox'
                                        checked={columnsCopy[c].isVisible}
                                        onChange={() => {
                                            if (
                                                Object.keys(columnsCopy).filter(
                                                    (key) => columnsCopy[key].isVisible
                                                ).length <= 1 &&
                                                columnsCopy[c].isVisible
                                            )
                                                return;
                                            setColumnsCopy({
                                                ...columnsCopy,
                                                [c]: {
                                                    ...columnsCopy[c],
                                                    isVisible: !columnsCopy[c].isVisible,
                                                },
                                            });
                                        }}
                                    />
                                    <div className={styles.checkboxLabel}>
                                        {columnsCopy[c].text}
                                    </div>
                                    <span className={styles.checkmark} />
                                </label>
                            </div>
                        ))}
                    </div>
                    <div
                        onClick={() => {
                            onClickSave();
                            close();
                        }}
                        className={styles.button}
                    >
                        Сохранить
                    </div>
                </div>
            )}
        </LongPopup>
    );
};
