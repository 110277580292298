import { Form } from 'antd';
import clsx from 'clsx';

import { Button } from '@components/Button';

import styles from '../styles.module.scss';

const requiredTouchedList = [
    'location',
    // 'index',
    // 'volume',
    // 'mass',
    // 'places',
    'workers_required',
    // 'shipment_type',
    'address',
];

const requiredList = [...requiredTouchedList, 'date'];

export const Controls = ({ step, nextStep, prevStep }) => {
    return (
        <Form.Item shouldUpdate className={styles.controls}>
            {({ getFieldsError, getFieldsValue, getFieldValue }) => {
                const isErrors = getFieldsError(requiredList).some(({ errors }) => errors.length);
                const isNotTime =
                    getFieldValue('time').every((time) => !time) &&
                    getFieldValue('time_interval').every((time) => !time);
                const isNotValues =
                    !Object.values(getFieldsValue(requiredList)).every((value) => {
                        if (Array.isArray(value)) {
                            return value.every((valueItem) => valueItem);
                        }

                        return value;
                    }) || isNotTime;

                const submitStyle = clsx(styles.button, {
                    [styles.disabled]: isErrors || isNotValues,
                });

                return (
                    <>
                        <Button disabled={step === 1} className={styles.button} onClick={prevStep}>
                            Назад
                        </Button>
                        {step !== 3 && (
                            <Button
                                disabled={step === 3}
                                className={styles.button}
                                onClick={nextStep}
                            >
                                Дальше
                            </Button>
                        )}
                        {step === 3 && (
                            <Button
                                htmlType='submit'
                                disabled={isErrors || isNotValues}
                                className={submitStyle}
                            >
                                Создать новую заявку
                            </Button>
                        )}
                    </>
                );
            }}
        </Form.Item>
    );
};

export const serviceRequiredRule = [
    (form) => {
        const services = form.getFieldsValue()?.services;
        return {
            message: '1',
            type: 'boolean',
            validator() {
                if (!services?.length) return Promise.resolve();
                if (
                    !!Array.isArray(services) &&
                    !!services?.length &&
                    services?.every((i) => !!i?.workers && !!i?.service)
                ) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
];
