import { showError } from '../../utils/showError';

import { fetch } from './fetch';

export const obtainToken = fetch(
    'OBTAIN_TOKEN',
    'gt/customer/obtain_token/',
    { method: 'POST' },
    undefined,
    showError
);
export const signup = fetch(
    'SIGNUP',
    'gt/customer/signup/',
    { method: 'POST' },
    undefined,
    showError
);
export const resetPassword = fetch(
    'RESET_PASSWORD',
    'gt/customer/reset_password/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
export const updatePassword = fetch(
    'UPDATE_PASSWORD',
    'gt/customer/update_password/',
    {
        method: 'POST',
    },
    undefined,
    showError
);
export const finishRegistration = fetch(
    'FINISH_REGISTRATION',
    'gt/customer/finish_registration/',
    {
        method: 'POST',
    },
    undefined,
    showError
);

export const sendVerificationCode = fetch(
    'SEND_VERIFICATION_CODE',
    'gt/customer/api/v1/auth/create_one_off_code/',
    {},
    undefined,
    showError
);

export const loginByPhone = fetch(
    'LOGIN_BY_PHONE',
    'gt/customer/api/v1/auth/login_by_phone/',
    {},
    undefined,
    showError
);

export const logout = () => {
    return {
        type: 'LOG_OUT',
    };
};
